import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Sorry from './Sorry'; // Import the Sorry component
import Img1 from "../images/img/cccd83_7419f3df9eb9432d8cf65a7abb2a8f68~mv2.jpg";
import Img2 from "../images/img/straight.jpg";
import Img3 from "../images/img/kitchenB.jpg";
import Img4 from "../images/img/kitchenA.jpg";
import Img5 from "../images/img/kitchenC.jpeg";
import "../colors/dmp.css"; 

const ModularKitchen = () => {
    const [filter, setFilter] = useState('all'); // State to track filter
    const [visibleImages, setVisibleImages] = useState(3); // State for number of visible images

    // Function to handle filter change
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setVisibleImages(3); // Reset visible images count when filter changes

        // Get all buttons and remove active class
        const buttons = document.querySelectorAll('.filter-buttons button');
        buttons.forEach(button => button.classList.remove('active'));

        // Add active class to the clicked button
        const activeButton = [...buttons].find(button => button.textContent.includes(newFilter));
        if (activeButton) {
            activeButton.classList.add('active');
        }
    }

    // Example images array (replace with your actual image data)
    const images = [
        { id: 1, src: Img1, category: 'Straight', link: Img1 },
        { id: 2, src: Img2, category: 'Straight', link: Img2 },
        { id: 3, src: Img3, category: 'Straight', link: Img3 },
        { id: 4, src: Img4, category: 'Straight', link: Img4 },
        { id: 5, src: Img5, category: 'Straight', link: Img5 },
    ];

    // Filtered images based on active filter
    const filteredImages = filter === 'all' ? images : images.filter(image => image.category === filter);

    // Function to load more images
    const loadMoreImages = () => {
        setVisibleImages(prev => prev + 4); // Increase visible images count by 4
    }

    return (
        <>
            <Navigationbar />

            <section className="news-detail-header-sections text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Modular Kitchen</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Modular Kitchen</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 col-12">
                            <div className="custom-text-box2">
                                <p className="mb-0">
                                    Experience the freedom of customization with our exquisite pre-made kitchen units, designed to seamlessly blend with your unique style and available space. Whether you’re drawn to the sleek lines of an L-shaped layout, the versatility of a U-shaped design, or the inviting ambiance of an open kitchen, our modular solutions cater to every preference.

                                    Functionality meets elegance in our kitchen designs, featuring abundant storage and innovative elements that transform cooking into a joyous experience. Discover the ideal interior design that resonates with your taste, creating a space that’s not only stunning but also practical.

                                    Explore our stylish collection of kitchen interiors today, and prepare to fall in love with your dream kitchen!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image-gallery section-padding">
                <div className="container">
                    <div className="fil-buttons text-center mb-4">
                        <button onClick={() => handleFilterChange('all')} className={filter === 'all' ? 'active' : ''}>All</button>
                        <button onClick={() => handleFilterChange('Straight')} className={filter === 'Straight' ? 'active' : ''}>Straight</button>
                        <button onClick={() => handleFilterChange('L-shape')} className={filter === 'L-shape' ? 'active' : ''}>L-Shape Kitchen</button>
                        <button onClick={() => handleFilterChange('U-shape')} className={filter === 'U-shape' ? 'active' : ''}>U-Shape Kitchen</button>
                        <button onClick={() => handleFilterChange('I-shape')} className={filter === 'I-shape' ? 'active' : ''}>I-Land Kitchen</button>
                        <button onClick={() => handleFilterChange('G-Shape')} className={filter === 'G-Shape' ? 'active' : ''}>G-Shape Kitchen</button>
                    </div>
                    <div className="row">
                        {filteredImages.length === 0 ? (
                            <Sorry />
                        ) : (
                            filteredImages.slice(0, visibleImages).map(image => (
                                <div 
                                    key={image.id} 
                                    className="col-lg-4 col-md-6 col-sm-6 mb-4 Moduimages"
                                    onMouseMove={(e) => {
                                        const img = e.currentTarget.querySelector('img');
                                        const rect = img.getBoundingClientRect();
                                        const x = (e.clientX - rect.left) / rect.width;
                                        const y = (e.clientY - rect.top) / rect.height;
                                        const rotateY = (x - 0.5) * 30; // Adjust rotation amount for visibility
                                        const rotateX = (0.5 - y) * 30; // Adjust rotation amount for visibility
                                        const rotateZ = (x - 0.5) * 10; // Adding Z-axis rotation
                                        img.style.transform = `rotateY(${rotateY}deg) rotateX(${rotateX}deg) rotateZ(${rotateZ}deg)`;
                                    }}
                                    onMouseLeave={(e) => {
                                        const img = e.currentTarget.querySelector('img');
                                        img.style.transform = 'rotateY(0deg) rotateX(0deg) rotateZ(0deg)'; // Reset rotation
                                    }}
                                >
                                    <a href={image.link} target="_blank" rel="noopener noreferrer">
                                        <img src={image.src} alt={`Image ${image.id}`} className="img-fluid" />
                                    </a>
                                </div>
                            ))
                        )}
                    </div>
                    {visibleImages < filteredImages.length && (
                        <div className="text-center mt-4">
                            <button className="load-more-btn" onClick={loadMoreImages}>Load More</button>
                        </div>
                    )}
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
                
            </section>

            <Footer />
        </>
    );
}

export default ModularKitchen;
