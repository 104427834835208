import React, { useRef, useEffect } from 'react';
import axios from 'axios';
import { Form, Input, Button, message } from "antd";
import "../colors/styles.css";
import Navigtionbar from '../components/Navigationbar';
import Footer from '../components/footer';

const Register = () => {
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const response = await axios.post('/api/users/register', values);
      message.success(response.data.message);
      form.resetFields();
    } catch (error) {
      message.error(error.response?.data?.message || 'Error registering user');
    }
  };

  const containerRef = useRef(null);
  const signInBtnRef = useRef(null);
  const signUpBtnRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const signInBtn = signInBtnRef.current;
    const signUpBtn = signUpBtnRef.current;

    if (!container || !signInBtn || !signUpBtn) {
      console.error("One or more elements are not found in the DOM");
      return;
    }

    const handleSignUpClick = () => {
      container.classList.add("sign-up-mode");
    };

    const handleSignInClick = () => {
      container.classList.remove("sign-up-mode");
    };

    signUpBtn.addEventListener("click", handleSignUpClick);
    signInBtn.addEventListener("click", handleSignInClick);

    return () => {
      signUpBtn.removeEventListener("click", handleSignUpClick);
      signInBtn.removeEventListener("click", handleSignInClick);
    };
  }, []);

  return (
    <div>
       <Navigtionbar />

       <section className="news-detail-header-sectionSing text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Register</h1>
                        </div>
                    </div>
                </div>
            </section>
      <section>
        <div className="login-container" ref={containerRef}>
          <div className="login-forms-container">
            <div className="login-signin-signup">
              <Form
                className="login-sign-up-form"
                form={form}
                onFinish={handleSubmit}
              >
                <h2 className="login-title">Sign up</h2>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input your Username!' }]}
                >
                  <div className="login-input-field">
                    <i className="fas fa-user"></i>
                    <Input type="text" placeholder="Username" />
                  </div>
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your Email!' }]}
                >
                  <div className="login-input-field">
                    <i className="fas fa-envelope"></i>
                    <Input type="email" placeholder="Email" />
                  </div>
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[{ required: true, message: 'Please input your Number!' }]}
                >
                  <div className="login-input-field">
                    <i className="fas fa-phone"></i>
                    <Input type="tel" placeholder="Phone" />
                  </div>
                </Form.Item>
                <button type="submit" className="registerButton">Sign up</button>
              </Form>
            </div>
          </div>

          <div className="login-panels-container">
            <div className="login-panel login-left-panel">
              <div className="login-content">
                <h2>Hit the button</h2>
                <p>
                for your dream home and start your journey to a stunning environment today!

                </p>
                <button
                  className="signupButton login-transparent"
                  onClick={() => containerRef.current.classList.add("sign-up-mode")}
                  ref={signUpBtnRef}
                >
                  Sign up
                </button>
              </div>
              <img src="img/log.svg" className="login-image" alt="" />
            </div>
            <div className="login-panel login-right-panel">
              <div className="login-content">
                <h2>Join Us</h2>
                <p>
                Transform your space with the expertise of Delhi Make Point.
                </p>
             
              </div>
              {/* <img src="img/register.svg" className="login-image" alt="" /> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />

    </div>
  );
};

export default Register;
