import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "../colors/dmp.css";


const createDroplets = (num) => {
  const droplets = [];
  for (let i = 0; i < num; i++) {
    const style = {
      left: `${Math.random() * 100}%`,
      animationDuration: `${Math.random() * 3 + 2}s`,
      animationDelay: `${Math.random() * 5}s`,
    };
    droplets.push(<div key={i} className="droplet" style={style}></div>);
  }
  return droplets;
};

const AdminLogin = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [message, setMessage] = useState('');
  const history = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData); // Log the form data
    try {
      const response = await axios.post('/api/admin/login', formData);
      localStorage.setItem('token', response.data.token);
      history('/admin');
    } catch (error) {
      console.error('Login Error:', error); // Log the error response
      setMessage(error.response ? error.response.data.message : 'Server Error');
    }
  };

  return (
    // <div>
    //   <form onSubmit={handleSubmit}>
    //     <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
    //     <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
    //     <button type="submit">Login</button>
    //   </form>
    //   {message && <p>{message}</p>}
    // </div>

    <div className="login-containers">
      {createDroplets(30)}
    <form className="login-form" onSubmit={handleSubmit}>
      <h2>Login</h2>
      {message && <p>{message}</p>}
      <div className="input-container">
        <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required  />
      </div>
      <div className="input-container">
        <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} required />
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
  );
};

export default AdminLogin;
