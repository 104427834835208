import React from 'react';
import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';

import '../colors/dmp.css'; // Ensure your custom CSS is imported
import image1 from '../images/img/bedroomE.jpeg';
import image2 from '../images/img/livingK.jpeg';
import image3 from '../images/img/livingE.jpeg';
import image4 from '../images/img/1 (2).jpeg';
import image5 from '../images/img/kitchenC.jpeg';
import image6 from '../images/img/kitchenB.jpg';
import 'animate.css';

const Collage = () => {
  const [ref1, inView1] = useInView({ triggerOnce: true });
  const [ref2, inView2] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });
  const [ref4, inView4] = useInView({ triggerOnce: true });
  const [ref5, inView5] = useInView({ triggerOnce: true });
  const [ref6, inView6] = useInView({ triggerOnce: true });

  return (
    <div className='container'>
      <h4 className="collage-heading animate__animated animate__fadeInRight">Innovative and Stylish Home Interior Inspirations</h4>
      <div className='row'>
        <div className="collage-container">
          <Link to="/about" className={`collage-item item1 ${inView1 ? 'animate__animated animate__backInDown' : ''}`} ref={ref1}>
            <img src={image1} alt="Image 1" className="collage-image" />
          </Link>
          <Link to="/about" className={`collage-item item2 ${inView2 ? 'animate__animated animate__backInRight' : ''}`} ref={ref2}>
            <img src={image2} alt="Image 2" className="collage-image" />
          </Link>
          <Link to="/about" className={`collage-item item3 ${inView3 ? 'animate__animated animate__backInUp' : ''}`} ref={ref3}>
            <img src={image3} alt="Image 3" className="collage-image" />
          </Link>
          <Link to="/about" className={`collage-item item4 ${inView4 ? 'animate__animated animate__backInUp' : ''}`} ref={ref4}>
            <img src={image4} alt="Image 4" className="collage-image" />
          </Link>
          <Link to="/about" className={`collage-item item5 ${inView5 ? 'animate__animated animate__backInLeft' : ''}`} ref={ref5}>
            <img src={image5} alt="Image 5" className="collage-image" />
          </Link>
          <Link to="/about" className={`collage-item item6 ${inView6 ? 'animate__animated animate__backInRight' : ''}`} ref={ref6}>
            <img src={image6} alt="Image 6" className="collage-image" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Collage;
