import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Img1 from "../images/img/vaastu-chakra.png";
import "../colors/dmp.css"; 

const Vastu = () => {
    const rotateStyle = {
        animation: 'spin 20s linear infinite'
    };

    return (
        <>
            <style>
                {`
                    @keyframes spin {
                        from { transform: rotate(0deg); }
                        to { transform: rotate(360deg); }
                    }
                `}
            </style>

            <Navigationbar />

            <section className="news-detail-header-sectionVa text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Vastu</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Vastu</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 col-12">
                            <div className="custom-text-box2">
                                <p className="mb-0">
                                Vastu Shastra is popularly known worldwide as an ancient Hindu practice that provides prosperity and positivity at home. Surprisingly, its principles extend beyond architectural constructions like towns, roads, temples, and plots. 
<br/>
<br/> 
                                Well, as the top <b> modular kitchen manufacturers in delhi and puja room interior designers in Delhi</b>. We can say that the reason behind incorporating Vastu Shastra in interior design is to maximize the positivity of a land area. And, to bring home the five elements of the galaxy - Air, Water, Fire, Earth, and Space!
<br/>
<br/> 

Thus, every element in your home, be it an interior decor piece, the colour of your sofa, or your bed facing a mirror in the bedroom. Everything creates an energy field that affects the lives of the habitats those who live in.
<br/>
<br/> 
So, if you are searching for the best interior designer near me day and out to activate the Vastu principles in your home decor then join our ideas. As, we are here to guide your way through easy and minimalistic interior design. Which is as per Vastu Shastra without breaking or bending your home's walls. 
                                </p>
                                <img src={Img1} alt="Vastu Image" className="medium-image" style={rotateStyle} />
                                <h2>Benefits of Vastu</h2>
                                <p>A man gets psychological benefits via Vastu in the form of well being; improvisation of relationships inwardly and outwardly, unlocks tension, reduces stress level and upgrades health.</p>
                                <ul>
                                    <li>Good health.</li>
                                    <li>Comfort.</li>
                                    <li>Convenience achieved by paying attention to place, ventilation, color, design, material, element and direction.</li>
                                    <li>Brings you harmony and fulfillment</li>
                                    <li>Vastu enhances the spiritual side of man.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Make your home <br /> Vastu-compliant!</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Vastu;
