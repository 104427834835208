import React from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Img1 from "../images/img/aid.jpg";
import Img2 from "../images/img/aid2.jpg";
import TrustedPartner from './trustedPartner';

import "../colors/dmp.css"; 

const AID = () => {


    return (
        
        <>
            <Navigationbar />

            <section className="AIDSection text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className=" col-12">
                            <h1 className="text-service">Architect & Interior Design</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding2" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb AIDBread">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Architect & Interior Design</li>
                                </ol>
                            </nav>
                        </div>
                     
                    </div>
                </div>
            </section>

            <section >
                <div className='container'>
                    <div className='row aidrow'>
                        <div className='col-lg-6 col-md-10'>
                            <div className='imagess'>
                                <img src={Img1}/>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-10'>
<div className='para'>
    <p>
    Designing spaces is not just about getting inspired by the trends, but also aligning them with the emotions and perceptions of the owners. That’s why we only focus on user-centric designs that are always customizable. With the combination of creativity and technical knowledge, we believe in creating spaces that can transform your place into an inspiring one.
    </p>

    <p>
    Delhi Make Point is among Delhi’s top architecture firms for both residential and commercial projects. We are a group of skilled specialists who believe that every assignment should push them further than before. We can assist you in creating the finest architectural project possible using the resources available by employing a dynamic approach to designing, planning, and maintenance.
    </p>
</div>
                        </div>
                    </div>

                    <div className='row aidrow aidrow2'>
                     
                        <div className='col-lg-6 col-md-10'>
<div className='para'>
    <p>
    This expertise is used exclusively in developing residential architectural projects and creating a world-class experience filled with customer satisfaction. We believe in making homes instead of just houses—exclusive pieces of art, modern thoughts, and creativity.
    </p>

    <p>
    Our portfolio showcases a diverse range of projects, each reflecting our commitment to excellence and innovation. From contemporary urban designs to classic architectural masterpieces, we ensure that each project is unique and tailored to meet the specific needs and desires of our clients.
    </p>
</div>
                        </div>

                        <div className='col-lg-6 col-md-10'>
                            <div className='imagess'>
                                <img src={Img2}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

         
<TrustedPartner/>
       

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default AID;
