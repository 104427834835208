import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Sorry from './Sorry'; 
import Img1 from "../images/img/commercials.jpeg";
import Img2 from "../images/img/commercial.jpg";
import Img3 from "../images/img/commercial2.jpg";
import Img4 from "../images/img/commercial3.jpg";
import Img5 from "../images/img/commercial4.jpg";
import Img6 from "../images/img/commercial5.jpg";
import Img7 from "../images/img/commercial6.jpg";
import Img8 from "../images/img/commercial7.jpg";
import Img9 from "../images/img/commercial8.jpg";
import "../colors/dmp.css"; 

const Commercial = () => {
    const [filter, setFilter] = useState('all'); // State to track filter
    const [visibleImages, setVisibleImages] = useState(5); // State for number of visible images

    // Function to handle filter change
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setVisibleImages(5); // Reset visible images count when filter changes
    }

    const images = [
        { id: 1, src: Img1 },
        { id: 2, src: Img2 },
        { id: 3, src: Img3 },
        { id: 4, src: Img4 },
        { id: 5, src: Img5 },
        { id: 6, src: Img6 },
        { id: 7, src: Img7 },
        { id: 8, src: Img8 },
        
    ];

    const filteredImages = filter === 'all' ? images : images.filter(image => image.category === filter);

    const loadMoreImages = () => {
        setVisibleImages(prev => prev + 4); // Increase visible images count by 4
    }

    return (
        
        <>
            <Navigationbar />

            <section className="news-detail-header-sectionCo text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Commercial</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Commercial</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 col-12">
                            <div className="custom-text-box2">
                                {/* <p className="mb-0"> */}

                                <ul>

                               <li> <b> Pops of Color : </b>Add Brighten up your workspace and make a bold statement with vibrant splashes of color. This simple addition can instantly elevate the mood and energy of the office.</li>
                               <li>
                               <b>Install Focal Walls : </b> Create visual interest and draw the eye by installing focal walls. These can serve as artistic centerpieces that inspire creativity and conversation.</li>
<li>
<b>Biodynamic Lighting : </b> Achieve the perfect balance between indoor and outdoor light with biodynamic lighting. This advanced lighting system helps regulate your circadian rhythm, enhancing productivity and well-being.</li>
<li>
<b>Incorporate Green Technologies : </b> Promote a healthier and more sustainable work environment by integrating green technologies. From energy-efficient appliances to indoor plants, these eco-friendly solutions can improve air quality and reduce your carbon footprint.</li>
<li>
<b>Open Door Policies : </b> Embrace transparency and inclusivity by adopting an open-door policy. This approach fosters a positive office culture where communication and collaboration thrive.</li>

By combining vibrant design, innovative lighting, sustainable practices, and a welcoming culture, you can create an office environment that not only stands out but also inspires and nurtures your team.
                                {/* </p> */}
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image-gallery section-padding">
                <div className="container">
                    <div className="row">
                        {images.length === 0 ? (
                            <Sorry />
                        ) : (
                            images.slice(0, visibleImages).map(image => (
                                <div key={image.id} className="col-lg-4 col-md-6 col-sm-6 mb-4 Moduimages">
                                    <img src={image.src} alt={`Image ${image.id}`} className="img-fluid" />
                                </div>
                            ))
                        )}
                    </div>
                    {visibleImages < images.length && (
                        <div className="text-center mt-4">
                            <button className="load-more-btn" onClick={loadMoreImages}>Load More</button>
                        </div>
                    )}
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Commercial;
