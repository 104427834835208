import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Pagination, message, Modal } from 'antd';
import * as XLSX from 'xlsx';
// import '';


const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(7);
  const history = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  // const [deletingUserId, setDeletingUserId] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          history('/admin/login');
          return;
        }
        const response = await axios.get('/api/users/users', {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log('API Response:', response.data);
        setUsers(response.data);
      } catch (error) {
        messageApi.error(error.response?.data?.message || 'Error fetching users');
      }
    };
    fetchData();
  }, [history, messageApi]);

  const handleDelete = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`/api/users/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.message) {
        setUsers(users.filter((user) => user._id !== id));
        messageApi.success(response.data.message);
      } else {
        messageApi.error('User deleted successfully, but no message was received.');
      }
    } catch (error) {
      messageApi.error(error.response?.data?.message || 'Error deleting user');
    }
  };

  const confirmDelete = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      onOk() {
        handleDelete(id);
      },
      onCancel() {
        console.log('Delete action canceled');
      },
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    history('/admin/login');
  };

  const handleExport = () => {
    const ws = XLSX.utils.json_to_sheet(users);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users');
    XLSX.writeFile(wb, 'users.xlsx');
    messageApi.success('Excel file created successfully!');
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedData = users.slice(startIndex, startIndex + pageSize);

  return (
    <div style={{ display: 'flex' }}>
      {contextHolder}
      <div style={{ width: '20%', padding: '20px', background: '#f0f2f5' }}>
        <h2>Sidebar</h2>
        <Button 
          onClick={handleExport} 
          type="default" 
          style={{ width: '100%', marginBottom: '10px' }}
        >
          Export Data
        </Button>
        <Button 
          onClick={handleLogout} 
          type="default" 
          style={{ width: '100%', marginBottom: '10px' }}
        >
          Logout
        </Button>
      </div>
      <div style={{ flex: 1, padding: '20px' }}>
        <center><h3>Admin Dashboard</h3></center>
        <Table dataSource={paginatedData} pagination={false}>
          <Table.Column 
            title="S.No" 
            render={(text, record, index) => <span>{startIndex + index + 1}</span>} 
          />
          <Table.Column title="Name" dataIndex="name" />
          <Table.Column title="Email" dataIndex="email" />
          <Table.Column title="Phone" dataIndex="phone" />
          <Table.Column
            title="Action"
            render={(text, record) => (
              <Button 
                type="danger" 
                onClick={() => confirmDelete(record._id)} 
                size="middle"
              >
                Delete
              </Button>
            )}
          />
        </Table>
        {paginatedData.length > 0 && (
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={users.length}
            onChange={page => setCurrentPage(page)}
            style={{ marginTop: '20px' }}
          />
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
