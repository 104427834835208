import React from 'react';


const Spinner =()=>{
    return(
        <>
       <div class="d-flex justify-content-center spinner">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
        </>
    )
};

// starting video from 2:00:36

export default Spinner;