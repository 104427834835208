import Carouselimgs from "../images/img/cccd83_7419f3df9eb9432d8cf65a7abb2a8f68~mv2.jpg";
import Carouselimgs2 from "../images/img/cccd83_1d0a21b99aa94d0e8adcc9e087466ba1~mv2.webp";
import Carouselimgs3 from "../images/img/Universal_Cinema_30.webp";
import Carouselimgs4 from "../images/img/cccd83_b3aacefb596f4404bcdded21c9337332~mv2.webp";
import Carouselimgs5 from "../images/img/cccd83_1d0a21b99aa94d0e8adcc9e087466ba1~mv2.webp";

const DesignDestinationData = [
    {
        id: 1,
        title: 'Modular Kitchen',
        category: 'Explore our innovative modular kitchen designs, crafted to blend functionality with style. Perfect for modern living.',
        linkImg: Carouselimgs,
        linkUrl: '/modularKitchen', // Update with the actual URL
    },
    {
        id: 2,
        title: 'Residential',
        category: 'Transform your living space into a beautiful home with our unique residential designs. Experience comfort and style every day.',
        linkImg: Carouselimgs2,
        linkUrl: '/residential', // Update with the actual URL
    },
    {
        id: 3,
        title: 'Commercial',
        category: 'Elevate your business space with our innovative commercial designs. Create an environment that inspires success and collaboration.',
        linkImg: Carouselimgs3,
        linkUrl: '/commercial', // Update with the actual URL
    },
    {
        id: 4,
        title: 'Renovation',
        category: 'Revitalize your space with our expert renovation services. Transform the old into something fresh and inspiring.',
        linkImg: Carouselimgs4,
        linkUrl: '/AID', // Update with the actual URL
    },
    {
        id: 5,
        title: 'Living Room',
        category: 'Transform your living room into a welcoming space for family and friends. Discover designs that blend comfort with elegance.',
        linkImg: Carouselimgs5,
        linkUrl: '/residential', // Update with the actual URL
    },
];

export default DesignDestinationData;
