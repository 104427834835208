import React from 'react';
import "../colors/dmp.css";
import image1 from "../images/img/bedroom.jpg";
import image2 from "../images/img/cccd83_7419f3df9eb9432d8cf65a7abb2a8f68~mv2.jpg";
import image3 from "../images/img/commercial8.jpg";
import image4 from "../images/img/construction.jpg";
import { useInView } from 'react-intersection-observer';

const OneStopForAllSolution = () => {

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
});

  return (
    <section className="section-padding" id="section_3">
      <div className="container">
        <div className="row OneStopCards">
          <div className={`col-lg-12 col-12 text-center mb-4 ${inView ? 'animate__animated animate__bounceInDown animate__delay-1s' : ''}`} ref={ref}>
            <h2>Your all-inclusive source for interior decor and design.</h2>
          </div>

          <div className={`oneStop col-lg-3  mb-4 mb-lg-0 ${inView ? 'animate__animated animate__bounceInLeft animate__delay-1s' : ''}`} ref={ref}>
            <div className="custom-block-wrap">
              <div className="custom-imgs">
                <img src={image1} className="custom-block-image img-fluid" alt="" />
              </div>
              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Home Interior</h5>
                  <p>Transform your living space with sleek designs and modern aesthetics. Create a cozy and stylish home today.</p>
                </div>
                <a href="/register" className="custom-btn btn">Get Free Estimate</a>
              </div>
            </div>
          </div>

          <div className={`oneStop col-lg-3  mb-4 mb-lg-0 ${inView ? 'animate__animated animate__bounceInUp animate__delay-1s' : ''}`} ref={ref}>
            <div className="custom-block-wrap">
              <div className="custom-imgs">
                <img src={image2} className="custom-block-image img-fluid" alt="" />
              </div>
              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Kitchen</h5>
                  <p>Upgrade your culinary space with functional and elegant kitchen designs. Cook, entertain, and enjoy in style.</p>
                </div>
                <a href="/register" className="custom-btn btn">Get Free Estimate</a>

              </div>
            </div>
          </div>

          <div className={`oneStop col-lg-3  mb-4 mb-lg-0 ${inView ? 'animate__animated animate__bounceInDown animate__delay-1s' : ''}`} ref={ref}>
            <div className="custom-block-wrap">
              <div className="custom-imgs">
                <img src={image3} className="custom-block-image img-fluid" alt="" />
              </div>
              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Office</h5>
                  <p>Design a productive and inspiring workspace with our innovative office solutions. Enhance efficiency and creativity.</p>
                </div>
                <a href="/register" className="custom-btn btn">Get Free Estimate</a>
              </div>
            </div>
          </div>

          <div className={`oneStop col-lg-3 ${inView ? 'animate__animated animate__bounceInRight animate__delay-1s' : ''}`} ref={ref}>
            <div className="custom-block-wrap">
              <div className="custom-imgs">
                <img src={image4} className="custom-block-image img-fluid" alt="" />
              </div>
              <div className="custom-block">
                <div className="custom-block-body">
                  <h5 className="mb-3">Civil Work</h5>
                  <p>Expertly executed civil projects with precision and quality. Building foundations for a better tomorrow.</p>
                </div>
                <a href="/register" className="custom-btn btn">Get Free Estimate</a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default OneStopForAllSolution;
