import React from 'react';
import "../colors/dmp.css"; 


const Sorry = () => {
    return (
        <div className="sorry-container">
            <div className="sorry-message">
                <p>Sorry, no image available 😢</p>
                <p>Maybe next time? 🤞</p>
            </div>
        </div>
    );
}

export default Sorry;
