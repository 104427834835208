import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LeftArrow from '../images/icons/left-arrow.svg';
import RightArrow from '../images/icons/right-arrow.svg';
import '../colors/sliderService.css';
import DesignDestinationData from "./DesignDestinationData"; 
import { useInView } from 'react-intersection-observer';

const SliderService = () => {
  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next" onClick={onClick}>
      <img src={RightArrow} alt="Next" />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev" onClick={onClick}>
      <img src={LeftArrow} alt="Previous" />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <div className="slider-container" ref={ref}>
      <Slider {...settings}>
        {DesignDestinationData.map((slide, index) => (
          <div
            className={`cards ${inView ? 'animate__animated animate__fadeInUp' : ''}`}
            key={slide.id}
            style={{
              animationDelay: `${index * 0.2}s`,  
              opacity: inView ? 1 : 0,
            }}
          >
            <div className="horizontal-card">
              <div className="images">
                <a href={slide.linkUrl} rel="noopener noreferrer">
                  <img src={slide.linkImg} className="card-img-top" alt={slide.title} />
                </a>
              </div>
              <div className="card-body">
                <a href={slide.linkUrl} rel="noopener noreferrer">
                  <h5 className="card-title">{slide.title}</h5>
                </a>
                <p className="card-text">{slide.category}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className='cardButtons'>
        <PrevArrow onClick={() => document.querySelector('.slick-prev').click()} />
        <NextArrow onClick={() => document.querySelector('.slick-next').click()} />
      </div>
    </div>
  );
};

export default SliderService;
