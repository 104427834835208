import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Sorry from './Sorry'; // Import the Sorry component
import Img1 from "../images/img/showroom.jpg";
import Img2 from "../images/img/showroom2.jpg";
import Img3 from "../images/img/showroom3.jpg";
import Img4 from "../images/img/showroom4.jpg";
import Img5 from "../images/img/showroom5.jpg";
import "../colors/dmp.css"; 

const Showroom = () => {
    const [filter, setFilter] = useState('all'); // State to track filter
    const [visibleImages, setVisibleImages] = useState(3); // State for number of visible images

    // Function to handle filter change
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setVisibleImages(3); // Reset visible images count when filter changes
    }

    const images = [
        { id: 1, src: Img1 },
        { id: 2, src: Img2 },
        { id: 3, src: Img3 },
        { id: 4, src: Img4 },
        { id: 5, src: Img5 },
     
        
    ];
    const filteredImages = filter === 'all' ? images : images.filter(image => image.category === filter);

    // Function to load more images
    const loadMoreImages = () => {
        setVisibleImages(prev => prev + 4); // Increase visible images count by 4
    }

    return (
        <>
            <Navigationbar />

            <section className="news-detail-header-sectionSho text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Showroom</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Showroom</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 col-12">
                            <div className="custom-text-box2">
                                <p className="mb-0">
                                Creating a standout showroom is an art that requires time, creativity, and the expertise of a skilled design company. At Delhi Make Point, we specialize in showroom interior design that captures attention and engages your audience, allowing them to visualize their lifestyle choices within the space.
<br/>
<br/> 
Our design philosophy centers around making a strong connection between the showroom and potential customers. Every detail counts, which is why we focus on thoughtful finishing touches and embellishments that elevate your showroom's overall appeal. With our deep understanding of the market and a proven track record, we know how to enhance existing interiors to maximize impact.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image-gallery section-padding">
                <div className="container">
                    <div className="row">
                        {images.length === 0 ? (
                            <Sorry />
                        ) : (
                            images.slice(0, visibleImages).map(image => (
                                <div key={image.id} className="col-lg-4 col-md-6 col-sm-6 mb-4 Moduimages">
                                    <img src={image.src} alt={`Image ${image.id}`} className="img-fluid" />
                                </div>
                            ))
                        )}
                    </div>
                    {visibleImages < images.length && (
                        <div className="text-center mt-4">
                            <button className="load-more-btn" onClick={loadMoreImages}>Load More</button>
                        </div>
                    )}
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Showroom;
