import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import "../src/App.css";
import { useSelector } from "react-redux";
import Spinner from "./components/Spinner";
import PublicRoute from './components/PublicRoute';
import MainPage from './pages/MainPage';
import About from './components/about';
import ModularKitchen from './components/modularKitchen';
import Contact from './components/contact';
import Residential from './components/residential.js';
import Commercial from './components/commercial';
import Showroom from './components/showroom';
import Vastu from './components/vastu';
import AID from './components/AID';
import Civilwork from './components/civilwork';
import Register from '../src/components/register.js';
import AdminDashboard from "../src/components/adminDashboard.js";
import Adminlogin from "../src/components/adminlogin.js";
import Underdevelopment from "../src/components/pageunderDev.js";
function App() {
  const { loading } = useSelector((state) => state.alerts);

  return (
    <>
      <BrowserRouter>
        {loading && <Spinner />}
        <Routes>
          {/* Public Routes */}
          <Route path='/about' element={<About />} />
          <Route path='/modularKitchen' element={<ModularKitchen />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/residential' element={<Residential />} />
          <Route path='/commercial' element={<Commercial />} />
          <Route path='/showroom' element={<Showroom />} />
          <Route path='/vastu' element={<Vastu />} />
          <Route path='/AID' element={<AID />} />
          <Route path='/civilwork' element={<Civilwork />} />
          <Route path='/register' element={<Register />} />
          <Route path='/admin/login' element={<Adminlogin  />} />
          <Route path='/admin' element={<AdminDashboard />} />
          <Route path='/underdevelopment' element={<Underdevelopment />} />
          
          {/* Protected Routes */}
          <Route path="/" element={
            <PublicRoute>
              <MainPage />
            </PublicRoute>
          } />
          
      
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
