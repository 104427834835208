import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Sorry from './Sorry'; 
import Img1 from "../images/img/construction.jpg";
import Img2 from "../images/img/construction2.jpg";
import Img3 from "../images/img/construction3.jpg";
import Img4 from "../images/img/construction4.jpg";

import "../colors/dmp.css"; 

const Civilwork = () => {
   
    const [filter, setFilter] = useState('all'); // State to track filter
    const [visibleImages, setVisibleImages] = useState(3); // State for number of visible images

    // Function to handle filter change
    const handleFilterChange = (newFilter) => {
        setFilter(newFilter);
        setVisibleImages(3); // Reset visible images count when filter changes
    }

    const images = [
        { id: 1, src: Img1 },
        { id: 2, src: Img2 },
        { id: 3, src: Img3 },
        { id: 4, src: Img4 },
     
        
    ];

 

    const loadMoreImages = () => {
        setVisibleImages(prev => prev + 4); // Increase visible images count by 4
    }

    return (
        <>
            <Navigationbar />

            <section className="news-detail-header-sectionCIV text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Civil Work</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding2" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Civil Work</li>
                                </ol>
                            </nav>
                        </div>
                     
                    </div>
                </div>
            </section>

            <section >
                <div className='container'>
                    <div className='row aidrow'>
                        <div className='col-lg-12 col-md-10'>
                            <h2>Civil Work</h2>
<div className='para'>
<p>At Delhi Make Point, our approach to civil work is founded on precision, durability, and innovation. We understand that civil work is the backbone of any construction project, and we are committed to delivering robust and reliable infrastructure that stands the test of time.</p>

<p>Our team of experienced engineers and project managers brings unparalleled expertise to every project, ensuring meticulous planning and execution. From initial site assessment to the final finishing touches, we prioritize quality at every step, employing state-of-the-art techniques and materials to achieve superior results.</p>
</div>
                        </div>
                    </div>

                    <div className='row aidrow aidrow2'>
                     
                        <div className='col-lg-6 col-md-10'>
<div className='para'>
<p>Our commitment to excellence extends beyond construction. We maintain rigorous standards for environmental responsibility and adhere to the latest safety regulations, ensuring that our projects not only meet but exceed industry benchmarks.</p>

<p> Delhi Make Point has a proven track record of successful civil work projects across Delhi. Our dedication to client satisfaction, combined with our technical expertise, positions us as a leader in the field. </p>

<p>We believe that effective communication and collaboration with our clients are key to the success of any project. Our transparent processes and regular updates ensure that clients are always informed and involved. This client-centric approach not only builds trust but also results in projects that perfectly align with the vision and requirements of our clients.</p>
</div>
                        </div>

                        <div className='col-lg-6 col-md-10'>
                            <div className='imagess'>
                                <img src={Img1}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image-gallery section-padding">
                <div className="container">
                    <div className="row">
                        {images.length === 0 ? (
                            <Sorry />
                        ) : (
                            images.slice(0, visibleImages).map(image => (
                                <div key={image.id} className="col-lg-4 col-md-6 col-sm-6 mb-4 Moduimages">
                                    <img src={image.src} alt={`Image ${image.id}`} className="img-fluid" />
                                </div>
                            ))
                        )}
                    </div>
                    {visibleImages < images.length && (
                        <div className="text-center mt-4">
                            <button className="load-more-btn" onClick={loadMoreImages}>Load More</button>
                        </div>
                    )}
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Civilwork;
