import React from 'react';
import 'animate.css';
import { useInView } from 'react-intersection-observer';

const Happycustomer = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });

    return (
        <>
            <section className={`cta-sections section-padding sectionbg-2 ${inView ? 'animate__animated animate__lightSpeedInRight' : ''}`} ref={ref}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className={`let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto ${inView ? 'animate__animated animate__rotateInDownLeft animate__delay-1s' : ''}`} ref={ref}>
                            <h2 className="mb-0">Your Dream <br /> Home</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8">
                            <a href="#" className={`me-4 ${inView ? 'animate__animated animate__rotateInDownRight animate__delay-2s' : ''}`} ref={ref} style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className={`custom-btn btn smoothscroll ${inView ? 'animate__animated animate__rotateInUpRight animate__delay-3s' : ''}`} ref={ref}>On Here</a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Happycustomer;
