import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../colors/dmp.css";
import bharatpe from "../images/brands/bharatpe.jpg";
import haveles from "../images/brands/godrej.png";
import herologo from "../images/brands/krrishProvence.png";
import daikin from "../images/brands/m3m.png";
import philips from "../images/brands/geetanjali.png";
import { useInView } from 'react-intersection-observer';


const brands = [
    { id: 1, image: bharatpe },
    { id: 2, image: haveles },
    { id: 3, image: herologo },
    { id: 4, image: daikin },
    { id: 5, image: philips },
    // { id: 6, image: thomascook },
    // Add more brands as needed
];

const TrustedPartner = () => {

    

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });
  

    return (
        <section className="trusted-partners">
            <div className="container">
                <h2 className={`${inView ? 'animate__animated animate__zoomIn' : ''}`} ref={ref}>Our Clients</h2>
                <Slider {...settings}>
                    {brands.map(brand => (
                        <div key={brand.id} className={`brand-slide ${inView ? 'animate__animated animate__zoomInDown' : ''}`} ref={ref}>
                            <img src={brand.image} alt={`Brand ${brand.id}`} className="brand-image" />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default TrustedPartner;
