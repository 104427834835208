import React from 'react';
import Navigation from "../components/Navigationbar";
import Carousel from '../components/Carousel';
import Whydmp from '../components/whydmp';
import Collage from '../components/collage';
import SliderService from '../components/SliderService';
import Happycustomer from '../components/Happycustomer';
import OneStopForAllSolution from '../components/oneStopForAllSolution';
import StepProgress from '../components/stepProgress';
import TrustedPartner from '../components/trustedPartner';
import Footer from '../components/footer';
import "../colors/mainpage.css";

const MainPage = () => {
    return (
        <div className="selectable-text">
            <Navigation />
            <Carousel />
            <Whydmp />
            <Collage />
            <SliderService />
            <Happycustomer />
            <OneStopForAllSolution />
            <StepProgress />
            <TrustedPartner />
            <Footer />
            
            {/* Home Button */}
            <div className="home_icon" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
                {/* <i className="fa-solid fa-house"></i> */}
                <i class="fa-solid fa-chevron-up"></i>
            </div>
            
            {/* WhatsApp Floating Button */}
            <div className="whatsapp_float">
                <a href="https://wa.me/917011969718" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-whatsapp"></i>
                </a>
            </div>
        </div>
    );
};

export default MainPage;
