import React from 'react';
import "../colors/dmp.css";
import Carouselimg from "../images/img/forCar.webp";
import Carouselimgs from "../images/img/livingF.jpeg";
import Carouselimgss from "../images/img/imageBlack-1.1.jpg";
import 'animate.css';

const Carousel=()=>{

   

    return(
        <>

<section className="hero-section hero-section-full-height">
                <div className="container-fluid">
                    <div className="row animate__animated animate__delay-1s">

                        <div className="col-lg-12 col-12 p-0">
                            <div id="hero-slide" className="carousel carousel-fade slide" data-bs-ride="carousel">
                                <div className="carousel-inner carousel-border">
                                    <div className="carousel-item active animate__animated animate__rubberBand ">
                                        <img src={Carouselimg} className="carousel-image img-fluid " alt="..."/>
                                        
                                        <div className="carousel-caption d-flex flex-column justify-content-end">
                                            <h2>Welcome to <br/> Delhi Make Point</h2>
                                            
                                            <p>Designs that Reflect Your Style.</p>
                                        </div>
                                    </div>

                                    <div className="carousel-item animate__animated animate__rubberBand">
                                        <img src={Carouselimgs} className="carousel-image img-fluid " alt="..."/>
                                        
                                        <div className="carousel-caption d-flex flex-column justify-content-end">
                                        <h2>Transforming Spaces,<br/> Realizing Dreams</h2>
<p>Discover the art of elegant living <br/>with our custom-designed living rooms that blend <br/>comfort and style seamlessly.</p>

                                        </div>
                                    </div>

                                    <div className="carousel-item animate__animated animate__rubberBand">
                                        <img src={Carouselimgss} className="carousel-image img-fluid " alt="..."/>
                                        
                                        <div className="carousel-caption d-flex flex-column justify-content-end">
                                            <h2>Redefine Your Workspace</h2>
                                            
                                            <p>Boost productivity with our innovative designs. Create<br/> a space that inspires and motivates</p>
                                        </div>
                                    </div>
                                </div>

                                <button className="carousel-control-prev" type="button" data-bs-target="#hero-slide" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>

                                <button className="carousel-control-next" type="button" data-bs-target="#hero-slide" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        
        </>
    )
}



export default Carousel;
