import React from 'react'
import "../colors/dmp.css";
import logo from "../images/logo/logo3.png"
import { useInView } from 'react-intersection-observer';


const Footer = () => {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });
    return (
        <>
           <footer class="site-footer">
            <div class="container">
                <div class="row">
                    <div class={` contact-info col-lg-3 col-12 mb-4 ${inView ? 'animate__animated animate__jackInTheBox' : ''}`} ref={ref}>
                        <img src={logo} class="logo img-fluid mb-2" alt=""/>
                        <p className='text-whites d-flex mb-2'> <p><p>At Delhi Make Point, we transform homes and offices into stylish spaces. Our expertise in interior, exterior, and civil design makes us a trusted name in the industry.</p>
                        </p>
                        </p>
                    </div>

                    <div class={`col-lg-4 col-md-6 col-12 mb-4 ${inView ? 'animate__animated animate__jackInTheBox animate__delay-1s' : ''}`} ref={ref}>
                        <h5 class="site-footer-title mb-3">Quick Links</h5>

                        <ul class="footer-menu">
                            <li class="footer-menu-item"><a href="/modularKitchen" class="footer-menu-link">Modular Kitchen</a></li>

                            <li class="footer-menu-item"><a href="/vastu" class="footer-menu-link">Vastu</a></li>

                            <li class="footer-menu-item"><a href="/residential" class="footer-menu-link">Residencial</a></li>

                            <li class="footer-menu-item"><a href="/commercial" class="footer-menu-link">Commercial</a></li>

                            <li class="footer-menu-item"><a href="/contact" class="footer-menu-link">Contact Us</a></li>
                            <li class="footer-menu-item"><a href="/register" class="footer-menu-link">Register Now</a></li>
                        </ul>
                    </div>

                    <div class={`col-lg-4 col-md-6 col-12 mx-auto ${inView ? 'animate__animated animate__jackInTheBox animate__delay-2s' : ''}`} ref={ref}>
                        <h5 class="site-footer-title mb-3">Contact Infomation</h5>

                        <p class="text-whites d-flex mb-2">
                            <i class="bi-telephone me-2"></i>

                            <a href="tel: 120-240-9600" class="site-footer-link">
                                +91-7011-969-718.
                            </a>
                        </p>

                        <p class="text-whites d-flex">
                            <i class="bi-envelope me-2"></i>

                            <a href="mailto:info@yourgmail.com" class="site-footer-link">
                                dmp@delhimakepoint.com
                            </a>
                        </p>

                        <p class="text-whites d-flex mt-3">
                            <i class="bi-geo-alt me-2"></i>
                           Saket, New Delhi.
                        </p>

                        <a href="https://maps.app.goo.gl/7hNJXaa1XT4KkKNE7" target='blank'  class="custom-btn btn mt-3">Get Direction</a>
                    </div>
                </div>
            </div>

            <div class={`site-footer-bottom ${inView ? 'animate__animated animate__slideInDown' : ''}`} ref={ref}>
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-md-7 col-12">
                            <p class="copyright-text mb-0">Copyright © 2021 <a href="/about">Delhi Make Point</a> </p>
                        </div>
                        
                        <div class="col-lg-6 col-md-5 col-12 d-flex justify-content-center align-items-center mx-auto">
                            <ul class="social-icon">
                                <li class="social-icon-item">
                                    <a href="/underdevelopment" class="social-icon-link bi-twitter"></a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="/underdevelopment" class="social-icon-link bi-facebook"></a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="/underdevelopment" class="social-icon-link bi-instagram"></a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="/underdevelopment" class="social-icon-link bi-linkedin"></a>
                                </li>

                                <li class="social-icon-item">
                                    <a href="/underdevelopment" class="social-icon-link bi-youtube"></a>
                                </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}

export default Footer