import React from 'react'
import "../colors/dmp.css";
import Aboutus from "../images/img/cccd83_69265d79e3094c69998dfc60a90057ca~mv2.webp";
import Navigtionbar from '../components/Navigationbar';
import Footer from './footer';

const About = () => {
    return (
        <>

        <Navigtionbar/>

        <section className="news-detail-header-section text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">About Us</h1>
                        </div>
                    </div>
                </div>
            </section>

           <section class="section-padding" id="section_2">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-6 col-12 mb-5 mb-lg-0">
                            <img src={Aboutus} class="custom-text-box-image img-fluid" alt=""/>
                        </div>

                        <div class="col-lg-6 col-12">
                            <div class="custom-text-box">
                                <h2 class="mb-2">Our Story</h2>

                                <h5 class="mb-3">Delhi Make Point</h5>

                                <p class="mb-0">At Delhi Make Point, we bring extensive expertise across diverse sectors, successfully completing projects by addressing the unique challenges each one presents, avoiding a "one-size-fits-all" approach. We are recognized as a premier interior solutions company, offering a wide range of services including tile flooring, aluminum works, modular kitchens, wall design, painting, decor, and more—all at affordable prices.

                                    <br/>
                                    <br/>

                                    Our dedicated team of architects and interior designers delivers exceptional interior, furniture, and lighting design services. We create contemporary living spaces that are both comfortable and elegant, while staying attuned to the latest trends.
                                </p>
                            </div>
                            
                            <div class="row">
                                <div class="col-lg-12 col-md-6 col-12">
                                    <div class="custom-text-box mb-lg-0">
                                        <h5 class="mb-3">Our Mission</h5>

                                        <p>“To align with our clients’ needs and exceed their expectations in quality, cost control, and time management.”</p>

                                     
                                    </div>
                                </div>

                               
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section class="cta-sections section-padding   sectionbg-2">
                <div class="container">
                    <div class="row justify-content-center align-items-center">

                        <div class="col-lg-5 col-12 ms-auto">
                            <h2 class="mb-0" style={{ color: '#fff' }}>Let's make your <br/> home beautiful.</h2>
                        </div>

                        <div class="col-lg-5 col-12">
                            <a href="#" class="me-4" style={{ color: '#fff' }}>Just one click</a>

                            <a href="/register" class="custom-btn btn smoothscroll">On Here</a>
                        </div>

                    </div>
                </div>
            </section>

            <Footer/>
        </>
    )
}

export default About