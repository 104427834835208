import React from 'react';
import "../colors/dmp.css";
import "../colors/mainpage.css";


import Navigationbar from '../components/Navigationbar';
import Footer from './footer';

const Contact = () => {
    return (
        <>
            <Navigationbar />

            <section className="news-detail-header-section text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Contact Us</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="contact-section section-padding" id="section_6">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-12 ms-auto mb-5 mb-lg-0">
                            <div className="contact-info-wrap">
                                <h2>Get in touch</h2>

                                <div className="contact-info">
                                    <h5 className="mb-3">Contact Information</h5>
                                    <p className="d-flex mb-2">
                                        <i className="bi-geo-alt me-2"></i>
                                        A-400, Durga Vihar, Khanpur New Delhi - 110080
                                    </p>
                                    <p className="d-flex mb-2">
                                        <i className="bi-telephone me-2"></i>
                                        <a href="tel:120-240-9600">+91- 7011-969-718</a>
                                    </p>
                                    <p className="d-flex">
                                        <i className="bi-envelope me-2"></i>
                                        <a href="mailto:info@yourgmail.com">dmp@delhimakepoint.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-5 col-12 mx-auto">
                            <div className="iframe-container">
                                <iframe 
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.3415229838406!2d77.22080617495185!3d28.499370790116203!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1fc5aff0879%3A0x9903df39c325bf2b!2sDelhi%20Make%20Point!5e0!3m2!1sen!2sin!4v1721534461442!5m2!1sen!2sin"
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

              {/* WhatsApp Floating Button */}
              <div className="whatsapp_float">
                <a href="https://wa.me/917011969718" target="_blank" rel="noopener noreferrer">
                    <i className="fa-brands fa-whatsapp"></i>
                </a>
            </div>
        </>
    );
}

export default Contact;
