import React from 'react';
import { useInView } from 'react-intersection-observer';
import icon1 from "../images/icons/livingroom (1).png";
import icon2 from "../images/icons/happy (1).png";
import icon3 from "../images/icons/business-ethics.png";
import icon4 from "../images/icons/professional-services.png";
import 'animate.css';

const Whydmp = () => {
    const { ref: ref1, inView: inView1 } = useInView({ triggerOnce: true });
    const { ref: ref2, inView: inView2 } = useInView({ triggerOnce: true });
    const { ref: ref3, inView: inView3 } = useInView({ triggerOnce: true });
    const { ref: ref4, inView: inView4 } = useInView({ triggerOnce: true });

    return (
        <>
            <section className="section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 col-12 text-center mx-auto">
                            <h2 className={`mb-5 ${inView1 ? 'animate__animated animate__bounce' : ''}`} ref={ref1}>Why Choose Us</h2>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className={`featured-block d-flex justify-content-center align-items-center ${inView2 ? 'animate__animated animate__backInLeft' : ''}`} ref={ref2}>
                                <a href="donate.html" className="d-block">
                                    <img src={icon4} className="featured-block-image img-fluid" alt="" />
                                    <p className="featured-block-text"><strong>Professional </strong> Designers</p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                            <div className={`featured-block d-flex justify-content-center align-items-center ${inView3 ? 'animate__animated animate__backInLeft' : ''}`} ref={ref3}>
                                <a href="donate.html" className="d-block">
                                    <img src={icon2} className="featured-block-image img-fluid" alt="" />
                                    <p className="featured-block-text"><strong>Happy</strong> Customer</p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0 mb-md-4">
                            <div className={`featured-block d-flex justify-content-center align-items-center ${inView4 ? 'animate__animated animate__backInRight' : ''}`} ref={ref4}>
                                <a href="donate.html" className="d-block">
                                    <img src={icon3} className="featured-block-image img-fluid" alt="" />
                                    <p className="featured-block-text"><strong>Transparency</strong></p>
                                </a>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
                            <div className={`featured-block d-flex justify-content-center align-items-center ${inView1 ? 'animate__animated animate__backInRight' : ''}`} ref={ref1}>
                                <a href="donate.html" className="d-block">
                                    <img src={icon1} className="featured-block-image img-fluid" alt="" />
                                    <p className="featured-block-text">Best<strong> Design Solutions</strong></p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Whydmp;
