import React,{useState, useEffect} from 'react';
import { Link, useNavigate } from "react-router-dom";
import "../colors/dmp.css";
// import logo from "../images/logo/logo.png";
import logo from "../images/logo/logo3.png";
import 'animate.css';


const Navigtionbar=()=>{

    const [isOpened, setIsOpened] = useState(false);

    const handleToggleClick = () => {
      setIsOpened((prevIsOpened) => !prevIsOpened);
    };

    const navigate = useNavigate();

    const handleBookDesignClick = () => {
      // Navigate to the "/register" route programmatically
      navigate('/register');
    };

   
  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.getElementById('navbar');
      if (window.pageYOffset > 20) {
        navbar.style.top = '0'; // Hide navbar when scrolled down
      } 
      else if(window.pageXOffset>50){
        navbar.style.top = '-47px';
      }
      else {
        navbar.style.top = '47px'; // Show navbar when at the top
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []);
    return(
        <>

 

            <header className="site-header animate__animated animate__rotateInDownLeft">
            <div className="container">
                <div className="row ">
                    
                    <div className="col-lg-8 col-12 d-flex flex-wrap">
                        <p className="d-flex me-1 mb-0">
                            {/* <i className="bi-geo-alt me-2"></i>
                            Akershusstranda 20, 0150 Oslo, Norway */}
                            <Link className="nav-link uppernav" to="/">Home</Link>
                        </p>

                        <p className="d-flex mb-0">
                            {/* <i className="bi-envelope me-2"></i> */}

                            {/* <a href="mailto:info@company.com">
                                info@company.com
                            </a> */}
                            <Link className="nav-link uppernav" to="/about">About</Link>
                        </p>
                        <p className="d-flex mb-0">
                            {/* <i className="bi-envelope me-2"></i> */}

                            {/* <a href="mailto:info@company.com">
                                info@company.com
                            </a> */}
                            <Link className="nav-link uppernav" to="/contact">Contact Us</Link>

                        </p>
                    </div>

                    <div className="col-lg-3 col-12 ms-auto d-lg-block d-none">
                        <ul className="social-icon">
                            <li className="social-icon-item">
                                {/* <a href="#" className="social-icon-link bi-facebook"></a> */}
                                <Link className=" social-icon-link nav-link uppernav" to="/register">Register</Link>

                            </li>

                            {/* <li className="social-icon-item">
                                <a href="#" className="social-icon-link bi-instagram"></a>
                            </li>

                            <li className="social-icon-item">
                                <a href="#" className="social-icon-link bi-youtube"></a>
                            </li>

                            <li className="social-icon-item">
                                <a href="#" className="social-icon-link bi-whatsapp"></a>
                            </li> */}
                        </ul>
                    </div>

                </div>
            </div>
        </header>

        <nav id='navbar' className="navbar navbar-expand-lg bg-light shadow-lg animate__animated animate__rotateInUpRight animate__delay-.7s">
            <div className="container">
                <a className="navbar-brand" href="/">
                    <img src={logo} className="logo img-fluid" alt="Delhi Make Point"/>
                    {/* <span>
                        Delhi Make Point
                        <small>Let's make your home beautiful</small>
                    </span> */}
                </a>

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                        <Link className="nav-link active" to="/modularKitchen">Modular Kitchen</Link>
                        </li>

                        <li className="nav-item">
                        <Link className="nav-link " to="/residential">Residential</Link>
                        </li>

                        <li className="nav-item">
                        <Link className="nav-link" to="/commercial">Commercial</Link>
                        </li>

                        <li className="nav-item">
                        <Link className="nav-link" to="/showroom">Showroom</Link>
                        </li>
                        <li className="nav-item">
                        <Link className="nav-link" to="/vastu">Vastu</Link>
                        </li>

                        <li className="nav-item dropdown">
                            <a className="nav-link click-scroll dropdown-toggle" href="#section_5" id="navbarLightDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">Others</a>

                            <ul className="dropdown-menu dropdown-menu-light" aria-labelledby="navbarLightDropdownMenuLink">
                            <li>
                                {/* <a className="dropdown-item" href="news-detail.html">Architect & Interior Design</a> */}
                        <Link className="dropdown-item nav-link" to="/AID">Architect & Interior Design</Link>

                                </li>
                            <li>
                          
                            <Link className="dropdown-item nav-link" to="/civilwork">Civil Work</Link>
                                {/* <a className="dropdown-item" href="news-detail.html">Civil Work</a> */}
                                </li>
                              
                            </ul>
                        </li>

                     

                        <li className="nav-item ms-3">
                            <a className="nav-link custom-btn custom-border-btn btn" href="/register">Book Your Design</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
    )
}



export default Navigtionbar;
