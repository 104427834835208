import React from 'react';
import "../colors/dmp.css"; 
import Navigtionbar from '../components/Navigationbar';



const PageuderDev = () => {
    return (
        <>
        <Navigtionbar/>
        <div className="sorry-container">
            <div className="sorry-message">
                <p>Sorry, Page under development 😢</p>
                <p>Maybe next time? 🤞</p>
            </div>
        </div>
        </>
    );

}

export default PageuderDev;
