import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
    name: 'alerts',
    initialState: {
        loading: false,
    },
    reducers: {
        showLoading: (state) => {
            state.loading = true;
        },
        hideLoading: (state) => {
            state.loading = false;
        },
    },
});

// Export the actions directly from alertSlice.actions, no need to re-export them
export const { showLoading, hideLoading } = alertSlice.actions;

// You can export the reducer as well if needed
export default alertSlice.reducer;
