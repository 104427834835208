import React, { useState } from 'react';
import Navigationbar from '../components/Navigationbar';
import Footer from './footer';
import Sorry from './Sorry'; // Import the Sorry component

import Img1 from "../images/img/residencial-1.jpg";
import Img2 from "../images/img/living&Dining.webp";
import Img3 from "../images/img/livingA.jpg";
import Img4 from "../images/img/livingB.jpg";
import Img5 from "../images/img/livingC.jpg";
import Img6 from "../images/img/livingD.jpeg";
import Img7 from "../images/img/livingE.jpeg";
import Img8 from "../images/img/livingF.jpeg";
import Img9 from "../images/img/livingG.jpeg";
import Img10 from "../images/img/living-H.jpeg";
import Img11 from "../images/img/living-I.jpeg";
import Img12 from "../images/img/living-J.jpeg";
import Img13 from "../images/img/livingK.jpeg";
import Img14 from "../images/img/living-L.jpeg";
import Img15 from "../images/img/living-M.jpeg";
import Img16 from "../images/img/living-N.jpeg";
import Img17 from "../images/img/livingO.jpeg";
import Img18 from "../images/img/livingP.jpeg";
import Img19 from "../images/img/residence.jpeg";
import Img20 from "../images/img/residencial-1.jpg";
import "../colors/dmp.css"; 

const Residential = () => {
    const [visibleImages, setVisibleImages] = useState(6); // State for number of visible images

    // Example images array (replace with your actual image data)
    const images = [
        { id: 1, src: Img1 },
        { id: 2, src: Img2 },
        { id: 3, src: Img3 },
        { id: 4, src: Img4 },
        { id: 5, src: Img5 },
        { id: 6, src: Img6 },
        { id: 7, src: Img7 },
        { id: 8, src: Img8 },
        { id: 9, src: Img9 },
        { id: 10, src: Img10 },
        { id: 11, src: Img11 },
        { id: 12, src: Img12 },
        { id: 13, src: Img13 },
        { id: 14, src: Img14 },
        { id: 15, src: Img15 },
        { id: 16, src: Img16 },
        { id: 17, src: Img17 },
        { id: 18, src: Img18 },
        { id: 19, src: Img19 },
        { id: 20, src: Img20 },
    ];

    // Function to load more images
    const loadMoreImages = () => {
        setVisibleImages(prev => prev + 6); // Increase visible images count by 4
    }

    return (
        <>
            <Navigationbar />

            <section className="news-detail-header-sectionr text-center">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <h1 className="text-service">Residential</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section-padding" id="section_2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Residential</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-12 col-12">
                            <div className="custom-text-box2">
                                <p className="mb-0">
                                    As one of the top interior decorators for residences in Delhi, Delhi Make Point specializes in transforming houses into dream homes. If you're investing a significant amount in your construction project and want it to perfectly reflect your vision, we are the best interior designers in Delhi to bring your dreams to life.
                                    <br/> <br/> 
                                    We understand that each project is unique, which is why we conduct a thorough analysis before starting. Our team of architects and designers meets with clients personally to discuss their requirements and budget. This initial process ensures that every detail is meticulously checked and reviewed, guaranteeing a flawless execution from start to finish.
                                    <br/> <br/> 
                                    With Delhi Make Point, you can be confident that your home will be a perfect blend of style, functionality, and your personal touch.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="image-gallery section-padding">
                <div className="container">
                    <div className="row">
                        {images.length === 0 ? (
                            <Sorry />
                        ) : (
                            images.slice(0, visibleImages).map(image => (
                                <div key={image.id} className="col-lg-4 col-md-6 col-sm-6 mb-4 Moduimages">
                                    <img src={image.src} alt={`Image ${image.id}`} className="img-fluid" />
                                </div>
                            ))
                        )}
                    </div>
                    {visibleImages < images.length && (
                        <div className="text-center mt-4">
                            <button className="load-more-btn" onClick={loadMoreImages}>Load More</button>
                        </div>
                    )}
                </div>
            </section>

            <section className="cta-sections section-padding sectionbg-2">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="let col-lg-9 col-md-6 col-sm-12 col-12 ms-auto">
                            <h2 className="mb-0">Let's make your <br /> home beautiful.</h2>
                        </div>
                        <div className="let lets col-lg-3 col-md-6 col-sm-8 ">
                            <a href="#" className="me-4" style={{ color: '#fff' }}>Just one click</a>
                            <a href="/register" className="custom-btn btn smoothscroll">On Here</a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}

export default Residential;
