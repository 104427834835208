import React, { useState, useEffect } from 'react';
import '../colors/dmp.css';
import image1 from '../images/img/living-L.jpeg';
import { useInView } from 'react-intersection-observer';

const steps = [
    'Understand your requirements',
    'Reimagine with a 3D design layout',
    'Material Selection within budget',
    'Execute the Design',
    'Step into your Beautiful Home'
];

const stepDescriptions = {
    'Understand your requirements': 'Craft ideal tailored design solutions for your needs and preferences.',
    'Reimagine with a 3D design layout': 'TExperts create 3D visuals, bringing your envisioned space to life vividly.',
    'Material Selection within budget': 'Guide in curating ideal style with materials, textures, furniture, budget. Create unique, perfect space.',
    'Execute the Design': 'Passionately reshape space, ensure quality, updates, seamless process for your vision.',
    'Step into your Beautiful Home':'Receive keys, warranty, completion letter - your dream home is ready!'
};

const StepProgress = () => {
    const [currentStep, setCurrentStep] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
        }, 3000); // Change step every 3 seconds

        return () => clearInterval(timer);
    }, []);

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1
    });
    
    return (
        <section className="section-padding" id="section_2">
            <div className="container">
                <div className="row step-row">
                    <div className={`col-lg-6 col-md-9 col-12 mb-5 mb-lg-0 step-image ${inView ? 'animate__animated animate__fadeInLeftBig animate__delay-0.5s' : ''}`} ref={ref}>
                        <img src={image1} className="custom-text-box-image2 img-fluid" alt=""/>
                    </div>
                    <div className={`col-lg-6 col-md-9 col-12 step-text ${inView ? 'animate__animated animate__fadeInRightBig animate__delay-0.5s' : ''}`} ref={ref}>
                        <div className="custom-text-box2">
                            <h2>Achieve stunning home interiors in just 5 steps</h2>
                            <div className="step-progress">
                                <div className="steps">
                                    {steps.map((step, index) => (
                                        <div key={index} className={`step ${index <= currentStep ? 'active' : ''}`}>
                                            <div className="step-number">{index + 1}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <br/>
                            <div className="step-content">
                                <h3 className="highlight-heading">{steps[currentStep]}</h3>
                                <p>{stepDescriptions[steps[currentStep]]} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    );
};

export default StepProgress;
